import { ConnectButton } from '@rainbow-me/rainbowkit'
import bg from './imgs/back.gif'
import fg from './imgs/land.jpg'
import mintGif from './imgs/mint.gif'
import dd from './imgs/dd.png'
import React from 'react'
import Web3 from 'web3'

import {
	useAccount,
	useChainId,
  usePrepareTransactionRequest,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from 'wagmi'
import { sendTransaction } from '@wagmi/core'
import { parseEther } from 'viem'
import { config } from './config'

import pendingGif from './imgs/pending.gif'
import flashing from './imgs/flashing.gif'
const Progress = (props) => (
	<div className="progress" onClick={props.click}>
		<div className="progress-text">{props.progress}% Complete</div>
		<div className="progress-bar"><div className={"progress-bar-inner progress-amount--"+ props.progress}></div></div>
	</div>
	)

const getTransactionReceipt = (hash, callback= ()=>{}) => {
    console.log(hash)
    window.ethereum.request({
      method: "eth_getTransactionReceipt",
      params: [hash]
    }).then((res)=>{
      if (res == null) {
        getTransactionReceipt(hash, callback)
      }
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.soniclabs.com'));



export function Web3App (props) {
	const [cost, setCost] = React.useState(100)  
	const [pending, setIsPending] = React.useState(false)
	const [pendingText, setPendingText] = React.useState("Transaction is in process") 
	const { address, isConnected } = useAccount()
	const chainId  = useChainId()

	const [networkConfig, setNetworkConfig] = React.useState(config["mainnet"])

	

	const sendRequest = (contract, data, callback = ()=>{}, payable = 0, gasAmount=0) => {
		setIsPending(true)
	

		if (typeof window.ethereum !== 'object') { 
		
		} else {
		

		window.ethereum.request({
			method: "eth_sendTransaction",
			params: [
			  {
			    from: address,
			    to: contract,
			    value: payable,
			    data: data,
			  },
			],}).then((hash)=>{
			  getTransactionReceipt(hash,(receipt)=>{
			    if (receipt != null) {
			      setIsPending(false)
			      callback()
			    }
			  })
			}).catch((err)=>{
			  setIsPending(false)
			})	
		}
		
	}

	const estimateGas = (contract, data, saveError, callback = ()=>{}, payable = 0) => {
	console.log("estimating gas", payable)
	console.log("window ethereum object", window.ethereum)
	console.log("typeof window ethereum",typeof(window.ethereum))
	// window.ethereum is not accessible in mobile browsers
	// need to replace this with an object that will let me send requests from browser
	// possible this object is exposed in wagmi
	if (typeof window.ethereum !== 'object') {
		alert("Please open this page in the Metamask browser")
		/*
		web3.eth.estimateGas(
			{
              from: address,
              to: contract,     
              value: payable,
              data: data,
            }).then((gasAmount)=>{
		      	console.log(gasAmount)
		        callback(gasAmount)
		      }).catch((err)=>{

		        saveError(err.data.message)
		        alert(err.data.message)
		      })*/
	} else {

		window.ethereum.request(
	        {
	          method: "eth_estimateGas",
	          params: [
	            {
	              from: address,
	              to: contract,     
	              value: payable,
	              data: data,
	            },
	          ],
	        }
	      ).then((gasAmount)=>{
	      	console.log(gasAmount)
	        callback(gasAmount)
	      }).catch((err)=>{

	        saveError(err.data.message)
	        alert(err.data.message)
	      })
	      
		}
   
}
	

	const derps = new web3.eth.Contract(networkConfig["derps"]["abi"], networkConfig["derps"]["address"])


	const [supplyPCT, setSupplyPCT] = React.useState(0)
	const getSupply = () => {

		 derps.methods.totalSupply()
		 	.call({from: address})
		 	.then((supply)=>{
		 		
		 		console.log(Number(supply))
				setSupplyPCT(Math.floor(100*Number(supply)/2000))
		 	})
	}


	React.useEffect(()=>{
		
		getSupply()

	},[networkConfig])




  const mint = () => {
  	
  	console.log("minting a derp")
		let data = derps.methods.mint().encodeABI()
		let addy = networkConfig["derps"]["address"]



		estimateGas(
			addy, 
			data, 
			(err)=>{console.log(err)},
			(gasAmount) => {
				setPendingText("Minting a Derp")
				setIsPending(true)
				
				sendRequest(
					addy, 
					data, 
					()=>{
						getSupply()
						getBal()
					},
					web3.utils.fromDecimal(web3.utils.toWei(cost, "ether")),
					gasAmount 
					)
					
			
			}
			

				,
			web3.utils.fromDecimal(web3.utils.toWei(cost, "ether")) 
			)
  	
  }

  const [style, setStyle] = React.useState({backgroundImage: `url(${fg})`})

  React.useEffect(
  	() => {
  		if (isConnected && !pending) {
  			setStyle({backgroundImage: `url(${bg})`})
  		} else {
  			if (pending) {
  				setStyle({backgroundImage: `url(${flashing})`})
  			} else {
  				if (isConnected) {
  					setStyle({backgroundImage: `url(${bg})`})
  				} else {
  					setStyle({backgroundImage: `url(${fg})`})
  				}
  				
  			}
  		}
  	},
  	[pending, isConnected]
  	)

  const [bal, setBal] = React.useState(0)
  const getBal = () => {
  	derps.methods.balanceOf(address)
  		.call({from: address})
  		.then((balance)=>{
  			setBal(balance)
  		})
  }

  return (
  	<div className={"App App--" + isConnected + " App-chain--" + chainId}>
	  	<div className="bg" style={style}>
	  		<div className="mint-box">
	  			<div className="mint-gif"><img src={mintGif} /></div>
	  			<div className="mint-text">1 Derp = 100 $S</div>
	  			<div className="mint-button"><button onClick={()=>{}}>Mint</button></div>
	  			<div className="mint-supply">2000 Derp Supply</div>
	  		</div>
	  		<Progress progress={5*Math.floor(supplyPCT/5)} click={()=>{
	  			getSupply()
	  			getBal()}
	  		}/>
	  
	  		<div className="dd"><img src={dd}/></div>
	  		<div className="connect-button">
	  			<div className="inliner docs"><a href="https://docs.derpe.xyz" target="_blank">DOCS</a></div>
	  			<div className="inliner"><ConnectButton /></div>
	  		</div>

	  		<div className={"pending pending--" + pending}>
	  			<p className="pending-title">Transaction Pending</p>
	  			<p className="pending-text">{pendingText}</p>
	  			<div className="pending-gif"><img src={pendingGif} /></div>
	  		</div>

	  		<div className={"balance balance--" + bal}>
	  			<div>{bal}</div>
	  		</div>
	  	</div>
	 
  	</div>
  	)
};